/*
    This file is currently empty, but is required as an entry point for Webpack
    to create the BERD theme.
*/

// following the recommendation of https://react.semantic-ui.com/theming#last-but-not-least-import-main-less-file
import "semantic-ui-less/semantic.less"; 
import "semantic-ui-css/semantic.js"; // required?
import "slick-carousel";

const $ = window.$;

$(document).ready(function(){

  /* Cards */

  $('.dimmable.card').dimmer({
    on: 'hover'
  });

  /* Carousels */

  $('#frontpage-partners .carousel').slick({
    arrows: false,
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: '#frontpage-partners .carousel-dots',
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
    ]
  });


  /* Fade-in effect (using the Intersection Observer API) */

  function fadeInCallback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('faded-in');
        observer.unobserve(entry.target);
      } 
    });
  }

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.25
  };

  const observer = new IntersectionObserver(fadeInCallback, observerOptions);

  const fadeIn = document.querySelectorAll('div.fading-in');
  fadeIn.forEach(el => observer.observe(el));

  // TODO Esteban
  /* User registration / profile */

  // information popups on registration form
  /*
  $('.registration-help')
    .popup({
      inline: true
    });
  */  

  // TODO Esteban
  // Populate interests/affiliations on registration and profile edit pages
  /*
  $('.ui.dropdown.interests, .ui.dropdown.affiliations')
    .dropdown({
        fullTextSearch: 'exact', // possible values: true (fuzzy match anywhere), false (match on start of string), exact (exact match somewhere in string)
        allowAdditions: true,
        hideAdditions: false,
        ignoreCase: true,
        minCharacters: 1,
        forceSelect: false,
        selectOnKeydown: false,
        selectOnNavigation: false,
        selectOnBlur: false,
        filterRemoteData: true,
        apiSettings: {
            beforeSend: function(settings){
              // Use the correct endpoint
              // Limit number of affiliation results to get better performace
              if (this.className.includes("interests")){
                settings.url = '/api/subjects?suggest={query}'
              } else {
                settings.url = '/api/affiliations?suggest={query}&size=10'
              }

              return settings
            },
            onResponse: function(response){
                // Need to provide expected format
                var results = [];
                var success = false;
                if (response.hits.total > 0){
                    success = true;
                    if (this.url.includes("subjects")){
                      response.hits.hits.forEach(function(item, index) {
                          results.push({
                              "name": item.subject,
                              "text": item.subject,
                              "value": item.subject,
                          })
                      })
                    } else {
                      response.hits.hits.forEach(function(item, index) {
                        results.push({
                            "short": item.acronym,
                            "name": item.name,
                            "text": item.name,
                            "value": item.name,
                        })
                    })
                    }
                }

                return {"success": success, "results": results};
            },
            url: '/api/subjects?suggest={query}',
            dataType: "json",
            method: "get"
        }
    });
    */

});

